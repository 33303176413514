/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import {
  HttpStatusCode,
  TalerError,
  assertUnreachable,
} from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { useOrderDetails } from "../../../../hooks/order.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { DetailPage } from "./DetailPage.js";

export interface Props {
  oid: string;
  onBack: () => void;
}

export default function Update({ oid, onBack }: Props): VNode {
  const result = useOrderDetails(oid);
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { lib: api } = useSessionContext();
  const { state } = useSessionContext();

  const { i18n } = useTranslationContext();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.BadGateway: {
        return <div>Failed to obtain a response from the exchange</div>;
      }
      case HttpStatusCode.GatewayTimeout: {
        return (
          <div>The merchant's interaction with the exchange took too long</div>
        );
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />

      <DetailPage
        onBack={onBack}
        id={oid}
        onRefund={(id, value) => {
          if (state.status !== "loggedIn") {
            return;
          }
          api.instance
            .addRefund(state.token, id, value)
            .then(() =>
              setNotif({
                message: i18n.str`refund created successfully`,
                type: "SUCCESS",
              }),
            )
            .catch((error) =>
              setNotif({
                message: i18n.str`could not create the refund`,
                type: "ERROR",
                description: error.message,
              }),
            );
        }}
        selected={result.body}
      />
    </Fragment>
  );
}
