/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { TalerMerchantApi } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import { CardTable as CardTableActive } from "./TableActive.js";

interface Props {
  instances: TalerMerchantApi.Instance[];
  onCreate: () => void;
  onUpdate: (id: string) => void;
  onDelete: (id: TalerMerchantApi.Instance) => void;
  onPurge: (id: TalerMerchantApi.Instance) => void;
  selected?: boolean;
}

export function View({
  instances,
  onCreate,
  onDelete,
  onPurge,
  onUpdate,
  selected,
}: Props): VNode {
  const [show, setShow] = useState<"active" | "deleted" | null>("active");
  const showIsActive = show === "active" ? "is-active" : "";
  const showIsDeleted = show === "deleted" ? "is-active" : "";
  const showAll = show === null ? "is-active" : "";
  const { i18n } = useTranslationContext();

  const showingInstances = showIsDeleted
    ? instances.filter((i) => i.deleted)
    : showIsActive
    ? instances.filter((i) => !i.deleted)
    : instances;

  return (
    <section class="section is-main-section">
      <div class="columns">
        <div class="column is-two-thirds">
          <div class="tabs" style={{ overflow: "inherit" }}>
            <ul>
              <li class={showIsActive}>
                <div
                  class="has-tooltip-right"
                  data-tooltip={i18n.str`Only show active instances`}
                >
                  <a onClick={() => setShow("active")}>
                    <i18n.Translate>Active</i18n.Translate>
                  </a>
                </div>
              </li>
              <li class={showIsDeleted}>
                <div
                  class="has-tooltip-right"
                  data-tooltip={i18n.str`Only show deleted instances`}
                >
                  <a onClick={() => setShow("deleted")}>
                    <i18n.Translate>Deleted</i18n.Translate>
                  </a>
                </div>
              </li>
              <li class={showAll}>
                <div
                  class="has-tooltip-right"
                  data-tooltip={i18n.str`Show all instances`}
                >
                  <a onClick={() => setShow(null)}>
                    <i18n.Translate>All</i18n.Translate>
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <CardTableActive
        instances={showingInstances}
        onDelete={onDelete}
        onPurge={onPurge}
        onUpdate={onUpdate}
        selected={selected}
        onCreate={onCreate}
      />
    </section>
  );
}
