/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Christian Blättler
 */

import { Duration, TalerMerchantApi } from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h } from "preact";
import { useState } from "preact/hooks";
import * as yup from "yup";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import { FormErrors, FormProvider } from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputDate } from "../../../../components/form/InputDate.js";
import { InputDuration } from "../../../../components/form/InputDuration.js";
import { TokenFamilyUpdateSchema } from "../../../../schemas/index.js";

type Entity = Omit<TalerMerchantApi.TokenFamilyUpdateRequest, "duration"> & {
  duration: Duration,
};

interface Props {
  onUpdate: (d: TalerMerchantApi.TokenFamilyUpdateRequest) => Promise<void>;
  onBack?: () => void;
  tokenFamily: TalerMerchantApi.TokenFamilyUpdateRequest;
}

function convert(from: TalerMerchantApi.TokenFamilyUpdateRequest) {
  const { duration, ...rest } = from;

  const converted = {
    duration: Duration.fromTalerProtocolDuration(duration),
  };
  return { ...converted, ...rest };
}

export function UpdatePage({ onUpdate, onBack, tokenFamily }: Props) {
  const [value, valueHandler] = useState<Partial<Entity>>(convert(tokenFamily));
  let errors: FormErrors<Entity> = {};

  try {
    TokenFamilyUpdateSchema.validateSync(value, {
      abortEarly: false,
    });
  } catch (err) {
    if (err instanceof yup.ValidationError) {
      const yupErrors = err.inner as yup.ValidationError[];
      errors = yupErrors.reduce(
        (prev, cur) =>
          !cur.path ? prev : { ...prev, [cur.path]: cur.message },
        {},
      );
    }
  }
  const hasErrors = Object.keys(errors).some(
    (k) => (errors as any)[k] !== undefined,
  );

  const submitForm = () => {
    if (hasErrors) return Promise.reject();

    const { duration, ...rest } = value as Required<Entity>;
    const result: TalerMerchantApi.TokenFamilyUpdateRequest = {
      ...rest,
      duration: Duration.toTalerProtocolDuration(duration),
    };

    return onUpdate(result);
  }

  const { i18n } = useTranslationContext();

  return (
    <div>
      <section class="section">
        <section class="hero is-hero-bar">
          <div class="hero-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-4">
                    Token Family: <b>{tokenFamily.name}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />

        <section class="section is-main-section">
          <div class="columns">
            <div class="column is-four-fifths">
              <FormProvider<Entity>
                name="token_family"
                errors={errors}
                object={value}
                valueHandler={valueHandler}
              >
                <Input<Entity>
                  name="name"
                  inputType="text"
                  label={i18n.str`Name`}
                  tooltip={i18n.str`user-readable token family name`}
                />
                <Input<Entity>
                  name="description"
                  inputType="multiline"
                  label={i18n.str`Description`}
                  tooltip={i18n.str`token family description for customers`}
                />
                <InputDate<Entity>
                  name="valid_after"
                  label={i18n.str`Valid After`}
                  tooltip={i18n.str`token family can issue tokens after this date`}
                  withTimestampSupport
                />
                <InputDate<Entity>
                  name="valid_before"
                  label={i18n.str`Valid Before`}
                  tooltip={i18n.str`token family can issue tokens until this date`}
                  withTimestampSupport
                />
                <InputDuration<Entity>
                  name="duration"
                  label={i18n.str`Duration`}
                  tooltip={i18n.str`validity duration of a issued token`}
                  withForever
                />
              </FormProvider>

              <div class="buttons is-right mt-5">
                {onBack && (
                  <button class="button" onClick={onBack}>
                    <i18n.Translate>Cancel</i18n.Translate>
                  </button>
                )}
                <AsyncButton
                  disabled={hasErrors}
                  data-tooltip={
                    hasErrors
                      ? i18n.str`Need to complete marked fields`
                      : "confirm operation"
                  }
                  onClick={submitForm}
                >
                  <i18n.Translate>Confirm</i18n.Translate>
                </AsyncButton>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
