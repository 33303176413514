/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { useMerchantApiContext } from "@gnu-taler/web-util/browser";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import {
  AccessToken,
  TalerHttpError,
  TalerMerchantManagementResultByMethod,
} from "@gnu-taler/taler-util";
import _useSWR, { SWRHook, mutate } from "swr";
import { useSessionContext } from "../context/session.js";
const useSWR = _useSWR as unknown as SWRHook;

export interface InstanceBankAccountFilter {}

export function revalidateInstanceBankAccounts() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "listBankAccounts",
    undefined,
    { revalidate: true },
  );
}
export function useInstanceBankAccounts() {
  const { state: session } = useSessionContext();
  const {
    lib: { instance },
  } = useSessionContext();

  // const [offset, setOffset] = useState<string | undefined>();

  async function fetcher([token, _bid]: [AccessToken, string]) {
    return await instance.listBankAccounts(token, {
      // limit: PAGINATED_LIST_REQUEST,
      // offset: bid,
      // order: "dec",
    });
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"listBankAccounts">,
    TalerHttpError
  >([session.token, "offset", "listBankAccounts"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  // return buildPaginatedResult(data.body.accounts, offset, setOffset, (d) => d.h_wire)
  const filtered = data.body.accounts.filter((a) => a.active);
  data.body.accounts = filtered;
  return data;
}

export function revalidateBankAccountDetails() {
  return mutate(
    (key) =>
      Array.isArray(key) && key[key.length - 1] === "getBankAccountDetails",
    undefined,
    { revalidate: true },
  );
}
export function useBankAccountDetails(h_wire: string) {
  const { state: session } = useSessionContext();
  const {
    lib: { instance },
  } = useSessionContext();

  async function fetcher([token, wireId]: [AccessToken, string]) {
    return await instance.getBankAccountDetails(token, wireId);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getBankAccountDetails">,
    TalerHttpError
  >([session.token, h_wire, "getBankAccountDetails"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}
