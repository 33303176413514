/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  FacadeCredentials,
  HttpStatusCode,
  OperationFail,
  OperationOk,
  PaytoString,
  TalerError,
  TalerMerchantApi,
  TalerRevenueHttpClient,
  assertUnreachable,
  opFixedSuccess,
} from "@gnu-taler/taler-util";
import {
  BrowserFetchHttpLib,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { NotificationCard } from "../../../../components/menu/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { Notification } from "../../../../utils/types.js";
import { CreatePage } from "./CreatePage.js";

export type Entity = TalerMerchantApi.AccountAddDetails;
interface Props {
  onBack?: () => void;
  onConfirm: () => void;
}

export default function CreateValidator({ onConfirm, onBack }: Props): VNode {
  const { lib: api } = useSessionContext();
  const { state } = useSessionContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const [tested, setTested] = useState(false);
  const { i18n } = useTranslationContext();

  return (
    <>
      <NotificationCard notification={notif} />
      <CreatePage
        onBack={onBack}
        onCreate={async (request: Entity) => {
          return api.instance
            .addBankAccount(state.token, request)
            .then((created) => {
              if (created.type === "fail") {
                setNotif({
                  message: i18n.str`could not create account`,
                  type: "ERROR",
                  description: created.detail.hint,
                });
                return;
              }
              onConfirm();
            })
            .catch((error) => {
              setNotif({
                message: i18n.str`could not create account`,
                type: "ERROR",
                description: error.message,
              });
            });
        }}
      />
    </>
  );
}

export enum TestRevenueErrorType {
  ANOTHER_ACCOUNT,
}

export async function testRevenueAPI(
  revenueAPI: URL,
  creds: FacadeCredentials | undefined,
): Promise<
  | OperationOk<PaytoString>
  | OperationFail<HttpStatusCode.NotFound>
  | OperationFail<HttpStatusCode.Unauthorized>
  | OperationFail<HttpStatusCode.BadRequest>
  | TalerError
> {
  const api = new TalerRevenueHttpClient(
    revenueAPI.href,
    new BrowserFetchHttpLib(),
  );
  const auth =
    creds === undefined
      ? undefined
      : creds.type === "none"
        ? undefined
        : creds.type === "basic"
          ? {
              username: creds.username,
              password: creds.password,
            }
          : undefined;

  try {
    const config = await api.getConfig(auth);

    if (config.type === "fail") {
      return config;
    }

    const resp = await api.getHistory(auth);
    if (resp.type === "fail") {
      return resp;
    }

    return opFixedSuccess(resp.body.credit_account as PaytoString);
  } catch (err) {
    if (err instanceof TalerError) {
      return err;
      // return {
      //   type: "fail",
      //   case: TestRevenueErrorType.GENERIC_ERROR,
      //   detail: err.errorDetail,
      // };
    }
    throw err;
  }
}
