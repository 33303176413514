/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  HttpStatusCode,
  TalerError,
  TalerMerchantApi,
  assertUnreachable,
} from "@gnu-taler/taler-util";
import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { useSessionContext } from "../../../../context/session.js";
import { useInstanceBankAccounts } from "../../../../hooks/bank.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { ListPage } from "./ListPage.js";

interface Props {
  onCreate: () => void;
  onSelect: (id: string) => void;
}

export default function ListOtpDevices({ onCreate, onSelect }: Props): VNode {
  const { i18n } = useTranslationContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { lib: api } = useSessionContext();
  const { state } = useSessionContext();
  const result = useInstanceBankAccounts();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />;
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      {result.body.accounts.length < 1 && (
        <NotificationCard
          notification={{
            type: "WARN",
            message: i18n.str`You need to associate a bank account to receive revenue.`,
            description: i18n.str`Without this the you won't be able to create new orders.`,
          }}
        />
      )}
      <ListPage
        devices={result.body.accounts}
        // onLoadMoreBefore={
        //   result.isFirstPage ? undefined: result.loadFirst
        // }
        // onLoadMoreAfter={result.isLastPage ? undefined : result.loadNext}
        onCreate={onCreate}
        onSelect={(e) => {
          onSelect(e.h_wire);
        }}
        onDelete={(e: TalerMerchantApi.BankAccountEntry) => {
          return api.instance
            .deleteBankAccount(state.token, e.h_wire)
            .then(() =>
              setNotif({
                message: i18n.str`bank account delete successfully`,
                type: "SUCCESS",
              }),
            )
            .catch((error) =>
              setNotif({
                message: i18n.str`could not delete the bank account`,
                type: "ERROR",
                description: error.message,
              }),
            );
        }}
      />
    </Fragment>
  );
}
