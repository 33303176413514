/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */
import { TalerMerchantApi } from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { NotificationCard } from "../../../components/menu/index.js";
import { useSessionContext } from "../../../context/session.js";
import { Notification } from "../../../utils/types.js";
import { CreatePage } from "./CreatePage.js";

interface Props {
  onBack?: () => void;
  onConfirm: () => void;
  forceId?: string;
}
export type Entity = TalerMerchantApi.InstanceConfigurationMessage;

export default function Create({ onBack, onConfirm, forceId }: Props): VNode {
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();
  const { lib } = useSessionContext();
  const { state, logIn } = useSessionContext();

  return (
    <Fragment>
      <NotificationCard notification={notif} />

      <CreatePage
        onBack={onBack}
        forceId={forceId}
        onCreate={async (
          d: TalerMerchantApi.InstanceConfigurationMessage,
        ) => {
          if (state.status !== "loggedIn") return;
          try {
            await lib.instance.createInstance(state.token, d);
            if (d.auth.token) {
              //if auth has been updated, request a new access token
              const result = await lib.authenticate.createAccessTokenBearer(
                d.auth.token,
                {
                  scope: "write",
                  duration: {
                    d_us: "forever",
                  },
                  refreshable: true,
                },
              );
              if (result.type === "ok") {
                const { token } = result.body;
                logIn(token);
              }
            }
            onConfirm();
          } catch (ex) {
            if (ex instanceof Error) {
              setNotif({
                message: i18n.str`Failed to create instance`,
                type: "ERROR",
                description: ex.message,
              });
            } else {
              console.error(ex);
            }
          }
        }}
      />
    </Fragment>
  );
}
