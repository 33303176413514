/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { HttpStatusCode, TalerError, TalerMerchantApi, TalerMerchantInstanceHttpClient, TalerMerchantManagementResultByMethod, assertUnreachable } from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../components/exception/loading.js";
import { NotificationCard } from "../../../components/menu/index.js";
import { useSessionContext } from "../../../context/session.js";
import {
  useInstanceDetails,
  useManagedInstanceDetails,
} from "../../../hooks/instance.js";
import { Notification } from "../../../utils/types.js";
import { LoginPage } from "../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../notfound/index.js";
import { UpdatePage } from "./UpdatePage.js";

export interface Props {
  onBack: () => void;
  onConfirm: () => void;

  // onUnauthorized: () => VNode;
  // onNotFound: () => VNode;
  // onLoadError: (e: HttpError<TalerErrorDetail>) => VNode;
  // onUpdateError: (e: HttpError<TalerErrorDetail>) => void;
}

export default function Update(props: Props): VNode {
  const { lib } = useSessionContext();
  const updateInstance = lib.instance.updateCurrentInstance.bind(lib.instance)
  const result = useInstanceDetails();
  return CommonUpdate(props, result, updateInstance,);
}

export function AdminUpdate(props: Props & { instanceId: string }): VNode {
  const { lib } = useSessionContext();
  const t = lib.subInstanceApi(props.instanceId).instance;
  const updateInstance = t.updateCurrentInstance.bind(t)
  const result = useManagedInstanceDetails(props.instanceId);
  return CommonUpdate(props, result, updateInstance,);
}


function CommonUpdate(
  {
    onBack,
    onConfirm,
  }: Props,
  result: TalerMerchantManagementResultByMethod<"getInstanceDetails"> | TalerError | undefined,
  updateInstance: typeof TalerMerchantInstanceHttpClient.prototype.updateCurrentInstance,
): VNode {
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();
  const { state } = useSessionContext();

  if (!result) return <Loading />
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />
  }
  if (result.type === "fail") {
    switch(result.case) {
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      default: {
        assertUnreachable(result)
      }
    }
  }

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <UpdatePage
        onBack={onBack}
        isLoading={false}
        selected={result.body}
        onUpdate={(
          d: TalerMerchantApi.InstanceReconfigurationMessage,
        ): Promise<void> => {
          if (state.status !== "loggedIn") {
            return Promise.resolve();
          }
          return updateInstance(state.token, d)
            .then(onConfirm)
            .catch((error: Error) =>
              setNotif({
                message: i18n.str`Failed to update instance`,
                type: "ERROR",
                description: error.message,
              }),
            );
        }}
      />
    </Fragment>
  );
}
