/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  TalerMerchantApi,
  stringifyPayTemplateUri
} from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { QR } from "../../../../components/exception/QR.js";
import { useSessionContext } from "../../../../context/session.js";

// type Entity = TalerMerchantApi.UsingTemplateDetails;

interface Props {
  contract: TalerMerchantApi.TemplateContractDetails;
  id: string;
  onBack?: () => void;
}

export function QrPage({ id: templateId, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();
  const { state } = useSessionContext();

  // const [state, setState] = useState<Partial<Entity>>({
  //   amount: contract.amount,
  //   summary: contract.summary,
  // });

  // const errors: FormErrors<Entity> = {};

  // const fixedAmount = !!contract.amount;
  // const fixedSummary = !!contract.summary;

  // const templateParams: Record<string, string> = {};
  // if (!fixedAmount) {
  //   if (state.amount) {
  //     templateParams.amount = state.amount;
  //   } else {
  //     templateParams.amount = config.currency;
  //   }
  // }

  // if (!fixedSummary) {
  //   templateParams.summary = state.summary ?? "";
  // }

  const merchantBaseUrl = state.backendUrl.href;

  const payTemplateUri = stringifyPayTemplateUri({
    merchantBaseUrl,
    templateId,
    // FIXME!
    //templateParams: {},
  });

  return (
    <div>
      <section id="printThis">
        <QR text={payTemplateUri} />
        <pre style={{ textAlign: "center" }}>
          <a target="_blank" rel="noreferrer" href={payTemplateUri}>{payTemplateUri}</a>
        </pre>
      </section>

      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            {/* <p class="is-size-5 mt-5 mb-5">
              <i18n.Translate>
                Here you can specify a default value for fields that are not
                fixed. Default values can be edited by the customer before the
                payment.
              </i18n.Translate>
            </p> */}

            <p></p>
            {/* <FormProvider
              object={state}
              valueHandler={setState}
              errors={errors}
            >
              <InputCurrency<Entity>
                name="amount"
                label={i18n.str`Amount`}
                readonly
                tooltip={i18n.str`Amount of the order`}
              />
              <Input<Entity>
                name="summary"
                inputType="multiline"
                readonly
                label={i18n.str`Summary`}
                tooltip={i18n.str`Title of the order to be shown to the customer`}
              />
            </FormProvider> */}

            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <button
                class="button is-info"
                onClick={() => saveAsPDF(templateId)}
              >
                <i18n.Translate>Print</i18n.Translate>
              </button>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}

function saveAsPDF(name: string): void {
  const printWindow = window.open("", "", "height=400,width=800");
  if (!printWindow) return;
  const divContents = document.getElementById("printThis");
  if (!divContents) return;
  printWindow.document.write(
    `<html><head><title>Order template for ${name}</title><style>`,
  );
  printWindow.document.write("</style></head><body>&nbsp;</body></html>");
  printWindow.document.close();
  printWindow.document.body.appendChild(divContents.cloneNode(true));
  printWindow.addEventListener("load", () => {
    printWindow.print();
    // printWindow.close();
  });
}
