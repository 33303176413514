/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { TalerMerchantApi } from "@gnu-taler/taler-util";
import { WithId } from "../../../../declaration.js";

type Entity = TalerMerchantApi.WebhookPatchDetails & WithId;

interface Props {
  onUpdate: (d: Entity) => Promise<void>;
  onBack?: () => void;
  webhook: Entity;
}
const validMethod = ["GET", "POST", "PUT", "PATCH", "HEAD"];

export function UpdatePage({ webhook, onUpdate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();

  const [state, setState] = useState<Partial<Entity>>(webhook);

  const errors: FormErrors<Entity> = {
    event_type: !state.event_type ? i18n.str`required` : undefined,
    http_method: !state.http_method
      ? i18n.str`required`
      : !validMethod.includes(state.http_method)
      ? i18n.str`should be one of '${validMethod.join(", ")}'`
      : undefined,
    url: !state.url ? i18n.str`required` : undefined,
  };

  const hasErrors = Object.keys(errors).some(
    (k) => (errors as any)[k] !== undefined,
  );

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    return onUpdate(state as any);
  };

  return (
    <div>
      <section class="section">
        <section class="hero is-hero-bar">
          <div class="hero-body">
            <div class="level">
              <div class="level-left">
                <div class="level-item">
                  <span class="is-size-4">
                    Webhook: <b>{webhook.id}</b>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </section>
        <hr />

        <section class="section is-main-section">
          <div class="columns">
            <div class="column is-four-fifths">
              <FormProvider
                object={state}
                valueHandler={setState}
                errors={errors}
              >
                <Input<Entity>
                  name="event_type"
                  label={i18n.str`Event`}
                  tooltip={i18n.str`The event of the webhook: why the webhook is used`}
                />
                <Input<Entity>
                  name="http_method"
                  label={i18n.str`Method`}
                  tooltip={i18n.str`Method used by the webhook`}
                />
                <Input<Entity>
                  name="url"
                  label={i18n.str`URL`}
                  tooltip={i18n.str`URL of the webhook where the customer will be redirected`}
                />
                <Input<Entity>
                  name="header_template"
                  label={i18n.str`Header`}
                  inputType="multiline"
                  tooltip={i18n.str`Header template of the webhook`}
                />
                <Input<Entity>
                  name="body_template"
                  inputType="multiline"
                  label={i18n.str`Body`}
                  tooltip={i18n.str`Body template by the webhook`}
                />
              </FormProvider>

              <div class="buttons is-right mt-5">
                {onBack && (
                  <button class="button" onClick={onBack}>
                    <i18n.Translate>Cancel</i18n.Translate>
                  </button>
                )}
                <AsyncButton
                  disabled={hasErrors}
                  data-tooltip={
                    hasErrors
                      ? i18n.str`Need to complete marked fields`
                      : "confirm operation"
                  }
                  onClick={submitForm}
                >
                  <i18n.Translate>Confirm</i18n.Translate>
                </AsyncButton>
              </div>
            </div>
          </div>
        </section>
      </section>
    </div>
  );
}
