/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import { AccessToken, TalerHttpError, TalerMerchantManagementResultByMethod } from "@gnu-taler/taler-util";
import _useSWR, { SWRHook, mutate } from "swr";
import { useSessionContext } from "../context/session.js";
const useSWR = _useSWR as unknown as SWRHook;

export function revalidateInstanceOtpDevices() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "listOtpDevices",
    undefined,
    { revalidate: true },
  );
}
export function useInstanceOtpDevices() {
  const { state: session } = useSessionContext();
  const { lib: { instance } } = useSessionContext();

  // const [offset, setOffset] = useState<string | undefined>();

  async function fetcher([token, _bid]: [AccessToken, string]) {
    return await instance.listOtpDevices(token, {
      // limit: PAGINATED_LIST_REQUEST,
      // offset: bid,
      // order: "dec",
    });
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"listOtpDevices">,
    TalerHttpError
  >([session.token, "offset", "listOtpDevices"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  // return buildPaginatedResult(data.body.otp_devices, offset, setOffset, (d) => d.otp_device_id)
  return data;
}

export function revalidateOtpDeviceDetails() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "getOtpDeviceDetails",
    undefined,
    { revalidate: true },
  );
}
export function useOtpDeviceDetails(deviceId: string) {
  const { state: session } = useSessionContext();
  const { lib: { instance } } = useSessionContext();

  async function fetcher([dId, token]: [string, AccessToken]) {
    return await instance.getOtpDeviceDetails(token, dId);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getOtpDeviceDetails">,
    TalerHttpError
  >([deviceId, session.token, "getOtpDeviceDetails"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}
