/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { HttpStatusCode, TalerError, TalerMerchantApi, assertUnreachable } from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { JumpToElementById } from "../../../../components/form/JumpToElementById.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { ConfirmModal } from "../../../../components/modal/index.js";
import { useSessionContext } from "../../../../context/session.js";
import {
  useInstanceProducts
} from "../../../../hooks/product.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { CardTable } from "./Table.js";
import { WithId } from "../../../../declaration.js";

interface Props {
  onCreate: () => void;
  onSelect: (id: string) => void;
}
export default function ProductList({
  onCreate,
  onSelect,
}: Props): VNode {
  const result = useInstanceProducts();
  const { lib } = useSessionContext();
  const { state } = useSessionContext();
  const [deleting, setDeleting] =
    useState<TalerMerchantApi.ProductDetail & WithId | null>(null);
  const [notif, setNotif] = useState<Notification | undefined>(undefined);

  const { i18n } = useTranslationContext();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  return (
    <section class="section is-main-section">
      <NotificationCard notification={notif} />

      <JumpToElementById
        testIfExist={async (id) => {
          const resp = await lib.instance.getProductDetails(state.token, id);
          return resp.type === "ok";
        }}
        onSelect={onSelect}
        description={i18n.str`jump to product with the given product ID`}
        placeholder={i18n.str`product id`}
      />

      <CardTable
        instances={result.body}
        onLoadMoreBefore={result.isFirstPage ? undefined : result.loadFirst}
        onLoadMoreAfter={result.isLastPage ? undefined : result.loadNext}
        onCreate={onCreate}
        onUpdate={async (id, prod) => {
          try {
            await lib.instance.updateProduct(state.token, id, prod);
            setNotif({
              message: i18n.str`product updated successfully`,
              type: "SUCCESS",
            });
          } catch (error) {
            setNotif({
              message: i18n.str`could not update the product`,
              type: "ERROR",
              description: error instanceof Error ? error.message : undefined,
            });
          }
          return
        }}
        onSelect={(product) => onSelect(product.id)}
        onDelete={(prod: TalerMerchantApi.ProductDetail & WithId) =>
          setDeleting(prod)
        }
      />

      {deleting && (
        <ConfirmModal
          label={`Delete product`}
          description={`Delete the product "${deleting.description}"`}
          danger
          active
          onCancel={() => setDeleting(null)}
          onConfirm={async (): Promise<void> => {
            try {
              await lib.instance.deleteProduct(state.token, deleting.id);
              setNotif({
                message: i18n.str`Product "${deleting.description}" (ID: ${deleting.id}) has been deleted`,
                type: "SUCCESS",
              });
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to delete product`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
            }
            setDeleting(null);
          }}
        >
          <p>
            If you delete the product named <b>&quot;{deleting.description}&quot;</b> (ID:{" "}
            <b>{deleting.id}</b>), the stock and related information will be lost
          </p>
          <p class="warning">
            Deleting an product <b>cannot be undone</b>.
          </p>
        </ConfirmModal>
      )}
    </section>
  );
}
