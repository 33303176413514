/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { HttpStatusCode, TalerError, TalerMerchantApi, assertUnreachable } from "@gnu-taler/taler-util";
import { VNode, h } from "preact";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import {
  useTemplateDetails
} from "../../../../hooks/templates.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { QrPage } from "./QrPage.js";
import { LoginPage } from "../../../login/index.js";

export type Entity = TalerMerchantApi.TransferInformation;
interface Props {
  onBack?: () => void;
  tid: string;
}

export default function TemplateQrPage({
  tid,
  onBack,
}: Props): VNode {
  const result = useTemplateDetails(tid);
  if (!result) return <Loading />
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />
  }
  if (result.type === "fail") {
    switch(result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(result)
      }
    }
  }


  return (
      <QrPage contract={result.body.template_contract} id={tid} onBack={onBack} />
  );
}
