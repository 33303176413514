/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { HttpStatusCode, TalerError, TalerMerchantApi, assertUnreachable } from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { JumpToElementById } from "../../../../components/form/JumpToElementById.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { ConfirmModal } from "../../../../components/modal/index.js";
import { useSessionContext } from "../../../../context/session.js";
import {
  useInstanceTemplates
} from "../../../../hooks/templates.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { ListPage } from "./ListPage.js";

interface Props {
  onCreate: () => void;
  onSelect: (id: string) => void;
  onNewOrder: (id: string) => void;
  onQR: (id: string) => void;
}

export default function ListTemplates({
  onCreate,
  onQR,
  onSelect,
  onNewOrder,
}: Props): VNode {
  const { i18n } = useTranslationContext();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { state, lib } = useSessionContext();
  const result = useInstanceTemplates();
  const [deleting, setDeleting] =
    useState<TalerMerchantApi.TemplateEntry | null>(null);

  if (!result) return <Loading />
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />
  }
  if (result.type === "fail") {
    switch(result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(result)
      }
    }
  }

  return (
    <section class="section is-main-section">
      <NotificationCard notification={notif} />

      <JumpToElementById
        testIfExist={async (id) => {
          const resp = await lib.instance.getTemplateDetails(state.token, id)
          return resp.type === "ok"
        }}
        onSelect={onSelect}
        description={i18n.str`jump to template with the given template ID`}
        placeholder={i18n.str`template id`}
      />

      <ListPage
        // templates={result.body}
        // onLoadMoreBefore={
        //   result.isFirstPage ? undefined: result.loadFirst
        // }
        // onLoadMoreAfter={result.isLastPage ? undefined : result.loadNext}
        
        templates={result.body.templates}
        onLoadMoreBefore={undefined}
        onLoadMoreAfter={undefined}

        onCreate={onCreate}
        onSelect={(e) => {
          onSelect(e.template_id);
        }}
        onNewOrder={(e) => {
          onNewOrder(e.template_id);
        }}
        onQR={(e) => {
          onQR(e.template_id);
        }}
        onDelete={(e: TalerMerchantApi.TemplateEntry) => {
          setDeleting(e)
        }
        }
      />

      {deleting && (
        <ConfirmModal
          label={`Delete template`}
          description={`Delete the template "${deleting.template_description}"`}
          danger
          active
          onCancel={() => setDeleting(null)}
          onConfirm={async (): Promise<void> => {
            try {
              await lib.instance.deleteTemplate(state.token, deleting.template_id);
              setNotif({
                message: i18n.str`Template "${deleting.template_description}" (ID: ${deleting.template_id}) has been deleted`,
                type: "SUCCESS",
              });
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to delete template`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
            }
            setDeleting(null);
          }}
        >
          <p>
            If you delete the template <b>&quot;{deleting.template_description}&quot;</b> (ID:{" "}
            <b>{deleting.template_id}</b>) you may loose information
          </p>
          <p class="warning">
            Deleting an template <b>cannot be undone</b>.
          </p>
        </ConfirmModal>
      )}
    </section>
  );
}
