/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Christian Blättler
 */

import { useTranslationContext } from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { StateUpdater, useState } from "preact/hooks";
import { format } from "date-fns";
import { MerchantBackend } from "../../../../declaration.js";
import { TalerMerchantApi } from "@gnu-taler/taler-util";

type Entity = TalerMerchantApi.TokenFamilySummary;

interface Props {
  instances: Entity[];
  onDelete: (tokenFamily: Entity) => void;
  onSelect: (tokenFamily: Entity) => void;
  onUpdate: (
    slug: string,
    data: MerchantBackend.TokenFamilies.TokenFamilyPatchDetail,
  ) => Promise<void>;
  onCreate: () => void;
  selected?: boolean;
}

export function CardTable({
  instances,
  onCreate,
  onSelect,
  onUpdate,
  onDelete,
}: Props): VNode {
  const [rowSelection, rowSelectionHandler] = useState<string | undefined>(
    undefined,
  );
  const { i18n } = useTranslationContext();
  return (
    <div class="card has-table">
      <header class="card-header">
        <p class="card-header-title">
          <span class="icon">
            <i class="mdi mdi-shopping" />
          </span>
          <i18n.Translate>Token Families</i18n.Translate>
        </p>
        <div class="card-header-icon" aria-label="more options">
          <span
            class="has-tooltip-left"
            data-tooltip={i18n.str`add token family`}
          >
            <button class="button is-info" type="button" onClick={onCreate}>
              <span class="icon is-small">
                <i class="mdi mdi-plus mdi-36px" />
              </span>
            </button>
          </span>
        </div>
      </header>
      <div class="card-content">
        <div class="b-table has-pagination">
          <div class="table-wrapper has-mobile-cards">
            {instances.length > 0 ? (
              <Table
                instances={instances}
                onSelect={onSelect}
                onDelete={onDelete}
                onUpdate={onUpdate}
                rowSelection={rowSelection}
                rowSelectionHandler={rowSelectionHandler}
              />
            ) : (
              <EmptyTable />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
interface TableProps {
  rowSelection: string | undefined;
  instances: Entity[];
  onSelect: (tokenFamily: Entity) => void;
  onUpdate: (
    slug: string,
    data: MerchantBackend.TokenFamilies.TokenFamilyPatchDetail,
  ) => Promise<void>;
  onDelete: (tokenFamily: Entity) => void;
  rowSelectionHandler: StateUpdater<string | undefined>;
}

function Table({
  rowSelection,
  rowSelectionHandler,
  instances,
  onSelect,
  onUpdate,
  onDelete,
}: TableProps): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="table-container">
      <table class="table is-fullwidth is-striped is-hoverable is-fullwidth">
        <thead>
          <tr>
            <th>
              <i18n.Translate>Slug</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Name</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Valid After</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Valid Before</i18n.Translate>
            </th>
            <th>
              <i18n.Translate>Kind</i18n.Translate>
            </th>
            <th />
          </tr>
        </thead>
        <tbody>
          {instances.map((i) => {
            return (
              <Fragment key={i.slug}>
                <tr key="info">
                  <td
                    onClick={() =>
                      rowSelection !== i.slug && rowSelectionHandler(i.slug)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {i.slug}
                  </td>
                  <td
                    onClick={() =>
                      rowSelection !== i.slug && rowSelectionHandler(i.slug)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {i.name}
                  </td>
                  <td
                    onClick={() =>
                      rowSelection !== i.slug && rowSelectionHandler(i.slug)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {i.valid_after.t_s === "never"
                      ? "never"
                      : format(new Date(i.valid_after.t_s * 1000), "yyyy/MM/dd hh:mm:ss")}
                  </td>
                  <td
                    onClick={() =>
                      rowSelection !== i.slug && rowSelectionHandler(i.slug)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {i.valid_before.t_s === "never"
                      ? "never"
                      : format(new Date(i.valid_before.t_s * 1000), "yyyy/MM/dd hh:mm:ss")}
                  </td>
                  <td
                    onClick={() =>
                      rowSelection !== i.slug && rowSelectionHandler(i.slug)
                    }
                    style={{ cursor: "pointer" }}
                  >
                    {i.kind}
                  </td>
                  <td class="is-actions-cell right-sticky">
                    <div class="buttons is-right">
                      <span
                        class="has-tooltip-bottom"
                        data-tooltip={i18n.str`go to token family update page`}
                      >
                        <button
                          class="button is-small is-success "
                          type="button"
                          onClick={(): void => onSelect(i)}
                        >
                          <i18n.Translate>Update</i18n.Translate>
                        </button>
                      </span>
                      <span
                        class="has-tooltip-left"
                        data-tooltip={i18n.str`remove this token family from the database`}
                      >
                        <button
                          class="button is-small is-danger"
                          type="button"
                          onClick={(): void => onDelete(i)}
                        >
                          <i18n.Translate>Delete</i18n.Translate>
                        </button>
                      </span>
                    </div>
                  </td>
                </tr>
              </Fragment>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}


function EmptyTable(): VNode {
  const { i18n } = useTranslationContext();
  return (
    <div class="content has-text-grey has-text-centered">
      <p>
        <span class="icon is-large">
          <i class="mdi mdi-emoticon-sad mdi-48px" />
        </span>
      </p>
      <p>
        <i18n.Translate>
          There are no token families yet, add the first one by pressing the + sign.
        </i18n.Translate>
      </p>
    </div>
  );
}
