/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { MerchantBackend } from "../declaration.js";
import { useSessionContext } from "../context/session.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import _useSWR, { SWRHook } from "swr";
import { AccessToken, TalerHttpError, TalerMerchantManagementResultByMethod } from "@gnu-taler/taler-util";
const useSWR = _useSWR as unknown as SWRHook;

export function useInstanceTokenFamilies() {
  const { state: session, lib: { instance } } = useSessionContext();

  // const [offset, setOffset] = useState<number | undefined>();

  async function fetcher([token, bid]: [AccessToken, number]) {
    return await instance.listTokenFamilies(token, {
      // limit: PAGINATED_LIST_REQUEST,
      // offset: bid === undefined ? undefined: String(bid),
      // order: "dec",
    });
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"listTokenFamilies">,
    TalerHttpError
  >([session.token, "offset", "listTokenFamilies"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  return data;
}

export function useTokenFamilyDetails(tokenFamilySlug: string) {
  const { state: session } = useSessionContext();
  const { lib: { instance } } = useSessionContext();

  async function fetcher([slug, token]: [string, AccessToken]) {
    return await instance.getTokenFamilyDetails(token, slug);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getTokenFamilyDetails">,
    TalerHttpError
  >([tokenFamilySlug, session.token, "getTokenFamilyDetails"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  return data;
}

export interface TokenFamilyAPI {
  createTokenFamily: (
    data: MerchantBackend.TokenFamilies.TokenFamilyAddDetail,
  ) => Promise<void>;
  updateTokenFamily: (
    slug: string,
    data: MerchantBackend.TokenFamilies.TokenFamilyPatchDetail,
  ) => Promise<void>;
  deleteTokenFamily: (slug: string) => Promise<void>;
}
