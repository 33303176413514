/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import {
  AmountString,
  Amounts,
  Duration,
  TalerError,
  TalerMerchantApi,
  TranslatedString,
} from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { AsyncButton } from "../../../../components/exception/AsyncButton.js";
import {
  FormErrors,
  FormProvider,
} from "../../../../components/form/FormProvider.js";
import { Input } from "../../../../components/form/Input.js";
import { InputCurrency } from "../../../../components/form/InputCurrency.js";
import { InputDuration } from "../../../../components/form/InputDuration.js";
import { InputNumber } from "../../../../components/form/InputNumber.js";
import { InputSelector } from "../../../../components/form/InputSelector.js";
import { InputToggle } from "../../../../components/form/InputToggle.js";
import { InputWithAddon } from "../../../../components/form/InputWithAddon.js";
import { TextField } from "../../../../components/form/TextField.js";
import { useSessionContext } from "../../../../context/session.js";
import { useInstanceOtpDevices } from "../../../../hooks/otp.js";

// type Entity = TalerMerchantApi.TemplateAddDetails & { type: Steps };
type Entity = {
  id?: string;
  description?: string;
  otpId?: string;
  summary?: string;
  amount?: AmountString;
  minimum_age?: number;
  pay_duration?: Duration;
  summary_editable?: boolean;
  amount_editable?: boolean;
  currency_editable?: boolean;
};

interface Props {
  onCreate: (d: TalerMerchantApi.TemplateAddDetails) => Promise<void>;
  onBack?: () => void;
}

export function CreatePage({ onCreate, onBack }: Props): VNode {
  const { i18n } = useTranslationContext();
  const { config } = useSessionContext();
  const { state: session } = useSessionContext();
  const devices = useInstanceOtpDevices();

  const [state, setState] = useState<Partial<Entity>>({
    minimum_age: 0,
    pay_duration: {
      d_ms: 1000 * 60 * 30, //30 min
    },
  });

  function updateState(up: (s: Partial<Entity>) => Partial<Entity>) {
    setState((old) => {
      const newState = up(old);
      if (!newState.amount_editable) {
        newState.currency_editable = false;
      }
      return newState;
    });
  }

  const parsedPrice = !state.amount ? undefined : Amounts.parse(state.amount);

  const errors: FormErrors<Entity> = {
    id: !state.id
      ? i18n.str`should not be empty`
      : !/[a-zA-Z0-9]*/.test(state.id)
        ? i18n.str`no valid. only characters and numbers`
        : undefined,
    description: !state.description ? i18n.str`should not be empty` : undefined,
    amount: !state.amount
      ? state.amount_editable ? undefined : i18n.str`required`
      : !parsedPrice
        ? i18n.str`not valid`
        : Amounts.isZero(parsedPrice)
          ? state.amount_editable ? undefined : i18n.str`must be greater than 0`
          : undefined,
    minimum_age:
      state.minimum_age && state.minimum_age < 0
        ? i18n.str`should be greater that 0`
        : undefined,
    pay_duration: !state.pay_duration
      ? i18n.str`can't be empty`
      : state.pay_duration.d_ms === "forever"
        ? undefined
        : state.pay_duration.d_ms < 1000 //less than one second
          ? i18n.str`to short`
          : undefined,
  };

  const cList = Object.values(config.currencies).map((d) => d.name);

  const hasErrors = Object.keys(errors).some(
    (k) => (errors as Record<string, unknown>)[k] !== undefined,
  );

  const zero = Amounts.stringify(Amounts.zeroOfCurrency(config.currency))

  const submitForm = () => {
    if (hasErrors) return Promise.reject();
    const contract_amount = state.amount_editable ? undefined : state.amount as AmountString
    const contract_summary = state.summary_editable ? undefined : state.summary
    const template_contract: TalerMerchantApi.TemplateContractDetails = {
      minimum_age: state.minimum_age!,
      pay_duration: Duration.toTalerProtocolDuration(state.pay_duration!),
      amount: contract_amount,
      summary: contract_summary,
      currency:
        cList.length > 1 && state.currency_editable
          ? undefined
          : config.currency,
    }
    return onCreate({
      template_id: state.id!,
      template_description: state.description!,
      template_contract,
      editable_defaults: {
        amount: !state.amount_editable ? undefined : (state.amount ?? zero),
        summary: !state.summary_editable ? undefined : (state.summary ?? ""),
        currency:
          cList.length === 1 || !state.currency_editable
            ? undefined
            : config.currency,
      },
      otp_id: state.otpId!,
    });
  };
  const deviceList =
    !devices || devices instanceof TalerError || devices.type === "fail"
      ? []
      : devices.body.otp_devices;
  const deviceMap = deviceList.reduce(
    (prev, cur) => {
      prev[cur.otp_device_id] = cur.device_description as TranslatedString;
      return prev;
    },
    {} as Record<string, TranslatedString>,
  );
  return (
    <div>
      <section class="section is-main-section">
        <div class="columns">
          <div class="column" />
          <div class="column is-four-fifths">
            <FormProvider
              object={state}
              valueHandler={updateState}
              errors={errors}
            >
              <InputWithAddon<Entity>
                name="id"
                help={
                  new URL(`templates/${state.id ?? ""}`, session.backendUrl.href).href
                }
                label={i18n.str`Identifier`}
                tooltip={i18n.str`Name of the template in URLs.`}
              />
              <Input<Entity>
                name="description"
                label={i18n.str`Description`}
                help=""
                tooltip={i18n.str`Describe what this template stands for`}
              />

              <Input<Entity>
                name="summary"
                inputType="multiline"
                label={i18n.str`Summary`}
                tooltip={i18n.str`If specified, this template will create order with the same summary`}
              />
              <InputToggle<Entity>
                name="summary_editable"
                label={i18n.str`Summary is editable`}
                tooltip={i18n.str`Allow the user to change the summary.`}
              />

              <InputCurrency<Entity>
                name="amount"
                label={i18n.str`Amount`}
                tooltip={i18n.str`If specified, this template will create order with the same price`}
              />
              <InputToggle<Entity>
                name="amount_editable"
                label={i18n.str`Amount is editable`}
                tooltip={i18n.str`Allow the user to select the amount to pay.`}
              />
              {cList.length > 1 && (
                <Fragment>
                  <InputToggle<Entity>
                    name="currency_editable"
                    readonly={!state.amount_editable}
                    label={i18n.str`Currency is editable`}
                    tooltip={i18n.str`Allow the user to change currency.`}
                  />
                  <TextField name="sc" label={i18n.str`Supported currencies`}>
                    <i18n.Translate>supported currencies: {cList.join(", ")}</i18n.Translate>
                  </TextField>
                </Fragment>
              )}
              <InputNumber<Entity>
                name="minimum_age"
                label={i18n.str`Minimum age`}
                help=""
                tooltip={i18n.str`Is this contract restricted to some age?`}
              />
              <InputDuration<Entity>
                name="pay_duration"
                label={i18n.str`Payment timeout`}
                help=""
                tooltip={i18n.str`How much time has the customer to complete the payment once the order was created.`}
              />
              {!deviceList.length ? (
                <TextField
                  name="otpId"
                  label={i18n.str`OTP device`}
                  tooltip={i18n.str`Use to verify transaction while offline.`}
                >
                  <i18n.Translate>No OTP device.</i18n.Translate>&nbsp;
                  <a href="/otp-devices/new">
                    <i18n.Translate>Add one first</i18n.Translate>
                  </a>
                </TextField>
              ) : (
                <InputSelector<Entity>
                  name="otpId"
                  label={i18n.str`OTP device`}
                  values={[
                    undefined,
                    ...deviceList.map((e) => e.otp_device_id),
                  ]}
                  toStr={(v?: string) => {
                    if (!v) {
                      return i18n.str`No device`;
                    }
                    return deviceMap[v];
                  }}
                  tooltip={i18n.str`Use to verify transaction in offline mode.`}
                />
              )}
            </FormProvider>

            <div class="buttons is-right mt-5">
              {onBack && (
                <button class="button" onClick={onBack}>
                  <i18n.Translate>Cancel</i18n.Translate>
                </button>
              )}
              <AsyncButton
                disabled={hasErrors}
                data-tooltip={
                  hasErrors
                    ? i18n.str`Need to complete marked fields`
                    : "confirm operation"
                }
                onClick={submitForm}
              >
                <i18n.Translate>Confirm</i18n.Translate>
              </AsyncButton>
            </div>
          </div>
          <div class="column" />
        </div>
      </section>
    </div>
  );
}
