/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Christian Blättler
 */

import {
  ErrorType,
  HttpError,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { h, VNode } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { MerchantBackend } from "../../../../declaration.js";
import {
  useInstanceTokenFamilies,
} from "../../../../hooks/tokenfamily.js";
import { Notification } from "../../../../utils/types.js";
import { CardTable } from "./Table.js";
import { HttpStatusCode, TalerError, TalerMerchantApi, assertUnreachable } from "@gnu-taler/taler-util";
import { useSessionContext } from "../../../../context/session.js";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { ConfirmModal } from "../../../../components/modal/index.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";

interface Props {
  onUnauthorized: () => VNode;
  onNotFound: () => VNode;
  onCreate: () => void;
  onSelect: (slug: string) => void;
  onLoadError: (e: HttpError<MerchantBackend.ErrorDetail>) => VNode;
}
export default function TokenFamilyList({
  onCreate,
  onSelect,
}: Props): VNode {
  const result = useInstanceTokenFamilies();
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { lib, state } = useSessionContext();
  const [deleting, setDeleting] =
    useState<TalerMerchantApi.TokenFamilySummary | null>(null);

  const { i18n } = useTranslationContext();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  return (
    <section class="section is-main-section">
      <NotificationCard notification={notif} />

      <CardTable
        instances={result.body.token_families}
        onCreate={onCreate}
        onUpdate={async (slug, fam) => {
          try {
            await lib.instance.updateTokenFamily(state.token, slug, fam);
            setNotif({
              message: i18n.str`token family updated successfully`,
              type: "SUCCESS",
            });
          } catch (error) {
            setNotif({
              message: i18n.str`could not update the token family`,
              type: "ERROR",
              description: error instanceof Error ? error.message : undefined,
            });
          }
          return;
        }}
        onSelect={(tokenFamily) => onSelect(tokenFamily.slug)}
        onDelete={(fam) => setDeleting(fam)}
      />

      {deleting && (
        <ConfirmModal
          label={`Delete token family`}
          description={`Delete the token family "${deleting.name}"`}
          danger
          active
          onCancel={() => setDeleting(null)}
          onConfirm={async (): Promise<void> => {
            try {
              await lib.instance.deleteTokenFamily(state.token, deleting.slug);
              setNotif({
                message: i18n.str`Token family "${deleting.name}" (SLUG: ${deleting.slug}) has been deleted`,
                type: "SUCCESS",
              });
            } catch (error) {
              setNotif({
                message: i18n.str`Failed to delete token family`,
                type: "ERROR",
                description: error instanceof Error ? error.message : undefined,
              });
            }
            setDeleting(null);
          }}
        >
          <p>
            If you delete the <b>&quot;{deleting.name}&quot;</b> token family (Slug:{" "}
            <b>{deleting.slug}</b>), all issued tokens will become invalid.
          </p>
          <p class="warning">
            Deleting a token family <b>cannot be undone</b>.
          </p>
        </ConfirmModal>
      )}
    </section>
  );
}
