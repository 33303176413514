/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */
import { PAGINATED_LIST_REQUEST } from "../utils/constants.js";

// FIX default import https://github.com/microsoft/TypeScript/issues/49189
import { AbsoluteTime, AccessToken, TalerHttpError, TalerMerchantManagementResultByMethod } from "@gnu-taler/taler-util";
import _useSWR, { SWRHook, mutate } from "swr";
import { useSessionContext } from "../context/session.js";
import { buildPaginatedResult } from "./webhooks.js";
const useSWR = _useSWR as unknown as SWRHook;



export function revalidateOrderDetails() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "getOrderDetails",
    undefined,
    { revalidate: true },
  );
}
export function useOrderDetails(oderId: string) {
  const { state: session } = useSessionContext();
  const { lib: { instance } } = useSessionContext();

  async function fetcher([dId, token]: [string, AccessToken]) {
    return await instance.getOrderDetails(token, dId);
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"getOrderDetails">,
    TalerHttpError
  >([oderId, session.token, "getOrderDetails"], fetcher);

  if (data) return data;
  if (error) return error;
  return undefined;
}

export interface InstanceOrderFilter {
  paid?: boolean;
  refunded?: boolean;
  wired?: boolean;
  date?: AbsoluteTime;
  position?: string;
}

export function revalidateInstanceOrders() {
  return mutate(
    (key) => Array.isArray(key) && key[key.length - 1] === "listOrders",
    undefined,
    { revalidate: true },
  );
}
export function useInstanceOrders(
  args?: InstanceOrderFilter,
  updatePosition: (d: string | undefined) => void = () => { },
) {
  const { state: session } = useSessionContext();
  const { lib: { instance } } = useSessionContext();

  // const [offset, setOffset] = useState<string | undefined>(args?.position);

  async function fetcher([token, o, p, r, w, d]: [AccessToken, string, boolean, boolean, boolean, AbsoluteTime]) {
    return await instance.listOrders(token, {
      limit: PAGINATED_LIST_REQUEST,
      offset: o,
      order: "dec",
      paid: p,
      refunded: r,
      wired: w,
      date: d,
    });
  }

  const { data, error } = useSWR<
    TalerMerchantManagementResultByMethod<"listOrders">,
    TalerHttpError
  >([session.token, args?.position, args?.paid, args?.refunded, args?.wired, args?.date, "listOrders"], fetcher);

  if (error) return error;
  if (data === undefined) return undefined;
  if (data.type !== "ok") return data;

  return buildPaginatedResult(data.body.orders, args?.position, updatePosition, (d) => String(d.row_id))
}
