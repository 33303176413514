/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Sebastian Javier Marchano (sebasjm)
 */

import { HttpStatusCode, TalerError, TalerMerchantApi, assertUnreachable } from "@gnu-taler/taler-util";
import {
  useTranslationContext
} from "@gnu-taler/web-util/browser";
import { Fragment, VNode, h } from "preact";
import { useState } from "preact/hooks";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import {
  useTemplateDetails
} from "../../../../hooks/templates.js";
import { Notification } from "../../../../utils/types.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";
import { UsePage } from "./UsePage.js";
import { useSessionContext } from "../../../../context/session.js";

export type Entity = TalerMerchantApi.TransferInformation;
interface Props {
  onBack?: () => void;
  onOrderCreated: (id: string) => void;
  tid: string;
}

export default function TemplateUsePage({
  tid,
  onOrderCreated,
  onBack,
}: Props): VNode {
  const { lib } = useSessionContext();
  const result = useTemplateDetails(tid);
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { i18n } = useTranslationContext();

  if (!result) return <Loading />
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />
  }
  if (result.type === "fail") {
    switch(result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(result)
      }
    }
  }

  return (
    <>
      <NotificationCard notification={notif} />
      <UsePage
        template={result.body}
        id={tid}
        onBack={onBack}
        onCreateOrder={(
          request: TalerMerchantApi.UsingTemplateDetails,
        ) => {

          return lib.instance.useTemplateCreateOrder(tid, request)
            .then((res) => {
              if (res.type === "ok") {
                onOrderCreated(res.body.order_id)
              } else {
                setNotif({
                  message: i18n.str`could not create order from template`,
                  type: "ERROR",
                });
              }
            })
            .catch((error) => {
              setNotif({
                message: i18n.str`could not create order from template`,
                type: "ERROR",
                description: error.message,
              });
            });
        }}
      />
    </>
  );
}
