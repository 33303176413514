/*
 This file is part of GNU Taler
 (C) 2021-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

/**
 *
 * @author Christian Blättler
 */

import {
  ErrorType,
  HttpError,
  useTranslationContext,
} from "@gnu-taler/web-util/browser";
import { Fragment, h, VNode } from "preact";
import { useState } from "preact/hooks";
import { Loading } from "../../../../components/exception/loading.js";
import { NotificationCard } from "../../../../components/menu/index.js";
import { Notification } from "../../../../utils/types.js";
import { UpdatePage } from "./UpdatePage.js";
import { HttpStatusCode, TalerError, TalerMerchantApi, assertUnreachable } from "@gnu-taler/taler-util";
import { useTokenFamilyDetails } from "../../../../hooks/tokenfamily.js";
import { useSessionContext } from "../../../../context/session.js";
import { ErrorLoadingMerchant } from "../../../../components/ErrorLoadingMerchant.js";
import { LoginPage } from "../../../login/index.js";
import { NotFoundPageOrAdminCreate } from "../../../notfound/index.js";

type Entity = TalerMerchantApi.TokenFamilyUpdateRequest;

interface Props {
  onBack?: () => void;
  onConfirm: () => void;
  slug: string;
}
export default function UpdateTokenFamily({
  slug,
  onConfirm,
  onBack,
}: Props): VNode {
  const result = useTokenFamilyDetails(slug);
  const [notif, setNotif] = useState<Notification | undefined>(undefined);
  const { lib, state } = useSessionContext();

  const { i18n } = useTranslationContext();

  if (!result) return <Loading />;
  if (result instanceof TalerError) {
    return <ErrorLoadingMerchant error={result} />;
  }
  if (result.type === "fail") {
    switch (result.case) {
      case HttpStatusCode.NotFound: {
        return <NotFoundPageOrAdminCreate />;
      }
      case HttpStatusCode.Unauthorized: {
        return <LoginPage />
      }
      default: {
        assertUnreachable(result);
      }
    }
  }

  const family: Entity = {
    name: result.body.name,
    description: result.body.description,
    description_i18n: result.body.description_i18n || {},
    duration: result.body.duration,
    valid_after: result.body.valid_after,
    valid_before: result.body.valid_before,
  };

  return (
    <Fragment>
      <NotificationCard notification={notif} />
      <UpdatePage
        tokenFamily={family}
        onBack={onBack}
        onUpdate={(data) => {
          return lib.instance.updateTokenFamily(state.token, slug, data)
            .then(onConfirm)
            .catch((error) => {
              setNotif({
                message: i18n.str`could not update token family`,
                type: "ERROR",
                description: error.message,
              });
            });
        }}
      />
    </Fragment>
  );
}
