/*
 This file is part of GNU Taler
 (C) 2022-2024 Taler Systems S.A.

 GNU Taler is free software; you can redistribute it and/or modify it under the
 terms of the GNU General Public License as published by the Free Software
 Foundation; either version 3, or (at your option) any later version.

 GNU Taler is distributed in the hope that it will be useful, but WITHOUT ANY
 WARRANTY; without even the implied warranty of MERCHANTABILITY or FITNESS FOR
 A PARTICULAR PURPOSE.  See the GNU General Public License for more details.

 You should have received a copy of the GNU General Public License along with
 GNU Taler; see the file COPYING.  If not, see <http://www.gnu.org/licenses/>
 */

import {
  HttpRequestLibrary,
  makeBasicAuthHeader,
  readTalerErrorResponse,
} from "../http-common.js";
import { HttpStatusCode } from "../http-status-codes.js";
import { createPlatformHttpLib } from "../http.js";
import { LibtoolVersion } from "../libtool-version.js";
import {
  FailCasesByMethod,
  ResultByMethod,
  opFixedSuccess,
  opKnownHttpFailure,
  opSuccessFromHttp,
  opUnknownFailure,
} from "../operation.js";
import {
  LongPollParams,
  PaginationParams,
  codecForRevenueConfig,
  codecForRevenueIncomingHistory,
} from "./types.js";
import { addLongPollingParam, addPaginationParams } from "./utils.js";

export type TalerBankRevenueResultByMethod<
  prop extends keyof TalerRevenueHttpClient,
> = ResultByMethod<TalerRevenueHttpClient, prop>;
export type TalerBankRevenueErrorsByMethod<
  prop extends keyof TalerRevenueHttpClient,
> = FailCasesByMethod<TalerRevenueHttpClient, prop>;

type UsernameAndPassword = {
  username: string;
  password: string;
};
/**
 * The API is used by the merchant (or other parties) to query
 * for incoming transactions to their account.
 */
export class TalerRevenueHttpClient {
  httpLib: HttpRequestLibrary;

  constructor(
    readonly baseUrl: string,
    httpClient?: HttpRequestLibrary,
  ) {
    this.httpLib = httpClient ?? createPlatformHttpLib();
  }

  public readonly PROTOCOL_VERSION = "0:0:0";

  isCompatible(version: string): boolean {
    const compare = LibtoolVersion.compare(this.PROTOCOL_VERSION, version);
    return compare?.compatible ?? false;
  }

  /**
   * https://docs.taler.net/core/api-bank-revenue.html#get--config
   *
   */
  async getConfig(auth?: UsernameAndPassword) {
    const url = new URL(`config`, this.baseUrl);
    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: auth
          ? makeBasicAuthHeader(auth.username, auth.password)
          : undefined,
      },
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForRevenueConfig());
      case HttpStatusCode.Unauthorized:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }
  /**
   * https://docs.taler.net/core/api-bank-revenue.html#get--history
   *
   * @returns
   */
  async getHistory(
    auth?: UsernameAndPassword,
    params?: PaginationParams & LongPollParams,
  ) {
    const url = new URL(`history`, this.baseUrl);
    addPaginationParams(url, params);
    addLongPollingParam(url, params);
    const resp = await this.httpLib.fetch(url.href, {
      method: "GET",
      headers: {
        Authorization: auth
          ? makeBasicAuthHeader(auth.username, auth.password)
          : undefined,
      },
    });
    switch (resp.status) {
      case HttpStatusCode.Ok:
        return opSuccessFromHttp(resp, codecForRevenueIncomingHistory());
      // FIXME: missing in docs
      case HttpStatusCode.NoContent:
        return opFixedSuccess({incoming_transactions: [], credit_account: "" });
      case HttpStatusCode.BadRequest:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.Unauthorized:
        return opKnownHttpFailure(resp.status, resp);
      case HttpStatusCode.NotFound:
        return opKnownHttpFailure(resp.status, resp);
      default:
        return opUnknownFailure(resp, await readTalerErrorResponse(resp));
    }
  }
}
